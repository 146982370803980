#navbar {
	margin: 0 auto;
	background-color: $primary;
	max-width: 1920px;
	width: 100%;
	position: relative;
	overflow-x: clip;
	padding-bottom: 40px;

	nav {
		display: flex;
		flex-direction: column;
		white-space: nowrap;
	}

	a {
		text-decoration: none;
		color: $light_grey;
	}
}

#top {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	text-align: center;

	img {
		margin-right: 10px;
	}

	.phone {
		padding: 21px 143px 20px 390px;
		font-weight: 700;
		background: linear-gradient(90deg, $primary 0%, #196541 100%);
		line-height: 1;

		a {
			display: flex;
		}
	}

	.bill {
		padding: 23px 185px;
		background-color: $primary_dark;
		font-weight: 700;
		line-height: 1;

		&:hover {
			background-color: #196541;
		}
	}
}

#bottom {
	display: flex;
	justify-content: space-between;
	line-height: 1;
	position: relative;

	.dropdown_background {
		background: rgba(0, 0, 0, .8);
		overflow: hidden;
		visibility: hidden;
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		bottom: -95px;
		z-index: 2;

		&.active {
			visibility: visible;
		}
	}

	.company_brand {
		margin-left: 8em;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 100%;
			height: auto;
			min-width: 205px;
			min-height: 40px;
		}
	}

	.hamburger {
		visibility: hidden;
		cursor: pointer;
		margin-right: 20px;
		color: white;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		width: 25px;
		height: 25px;
		padding: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: content-box;

		&.open {
			transform: rotate(135deg);
			transition: transform .3s ease-in-out;

			div:before {
				transform: rotate(90deg);
				top: 0;
				transition: transform .3s ease-in-out;
			}

			div:after {
				transform: rotate(90deg);
				top: 0;
				transition: transform .3s ease-in-out;
			}
		}

		div {
			position: relative;
			flex: none;
			width: 100%;
			height: 2px;
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: center;

			&:after, &:before {
				content: '';
				position: absolute;
				z-index: 1;
				top: -10px;
				width: 100%;
				height: 2px;
				background: inherit;
			}

			&:after {
				top: 10px;
			}
		}
	}

	.nav_items {
		display: flex;
		justify-content: space-between;
		align-items: center;

		ul {
			display: flex;
			align-items: center;

			.item {
				color: #fff;
				cursor: pointer;
				display: flex;
				justify-content: center;
				padding: 45px 35px;
				font-weight: 700;
				position: relative;

				&.mobile_only {
					display: none;
					visibility: hidden;
				}

				h3:first-child::before {
					content: '';
					position: absolute;
					display: block;
					visibility: hidden;
					width: 12px;
					height: 12px;
					border-top: 4px solid #364f46;
					border-left: 4px solid #364f46;
					right: 10px;
					top: 20px;
					transform: rotate(45deg);
				}

				.dropdown {
					cursor: auto;
					position: absolute;
					left: 0;
					top: 95px;
					z-index: 101;
					padding: 40px 0;
					margin: 0;
					// padding: 40px 1000em;
					// margin: 0 -1000em;
					visibility: hidden;
					// width: 100%;
					display: grid;
					grid-auto-flow: column dense;
					grid-template-rows: 0px 50px;
					opacity: 0;

					li {
						width: 50%;
						line-height: 1.5;
						font-weight: 500;
						font-size: .9rem;
						list-style-type: square;
						color: $primary;
						margin-left: 50px;
						z-index: 1000;

						a:hover {
							text-decoration: underline;
							text-underline-offset: 2px;
						}
					}
				}

				&:not(:last-child)::after {
					content: '';
					position: absolute;
					visibility: hidden;
					bottom: -19px;
					width: 0;
					z-index: 101;
					height: 0;
					border-left: 20px solid transparent;
					border-right: 20px solid transparent;
					border-top: 30px solid $primary;
				}

				&:not(.link):hover {

					&:after {
						visibility: visible;
					}

					ul {
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}

		.btn {
			width: 165px;
			font-size: .9rem;
			font-weight: 800;
			text-align: center;
			margin-right: 30px;
			padding: 12px;
			cursor: pointer;
			border: 1px solid $light_grey;
			line-height: 1;
			box-sizing: content-box;
		}

		.mbl_nav {
			background-color: #364f46;
			visibility: hidden;
			display: none;

			&:hover {
				background-color: $primary_dark;
				text-underline-offset: 2px;
			}
		}
	}
}

#promo {
	width: 100%;
	height: 50px;
	position: absolute;
	bottom: -40px;
	overflow: hidden;
	display: flex;
	z-index: 1;
	justify-content: center;
	align-items: center;
	background: radial-gradient(circle, #2b388f 0%, #262261 50%, #2b388f 100%);

	&::before {
		content: '';
		position: absolute;
		transform: rotate(-20deg);
		top: -20px;
		left: 28%;
		width: 20px;
		height: 250%;
		background: #2b388f;
	}

	&::after {
		content: '';
		position: absolute;
		transform: rotate(20deg);
		top: -20px;
		right: 28%;
		width: 20px;
		height: 250%;
		z-index: 0;
		background: #2b388f;
	}

	.promo_item {
		height: 100%;
		display: none;
		align-items: center;

		&.active {
			display: flex;
		}
	}

	.promo_container {
		position: relative;
		height: 100%;
		z-index: 1;

		&::before, &::after {
			content: " ";
			position: absolute;
			top: 50%;
			margin-left: -999em;
			height: 2px;
			width: 991em;
			border-top: 1px solid $primary;
		}

		&::after {
			left: 100%;
			width: 999em;
			margin: 0 0 0 8em;
		}
	}

	.promo_item_content {
		display: flex;
		align-items: center;

		p {
			color: #fff;
			font-weight: 300;
			font-size: 1.2rem;
			margin: 0 20px;
			text-align: center;
			white-space: nowrap;

			strong {
				font-weight: 800;
			}
		}

		a, button {
			cursor: pointer;
			text-align: center;
			border: 1px solid $primary;
			border-radius: 5px;
			padding: 10px;
		}
	}
}




.modal-overlay {
	display: none;
	position: fixed;
	z-index: 3;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	// overflow: auto;
	background-color: rgba(0, 0, 0, .5);

	&.show-modal {
		display: block;
	}
}

.modal-content {
	border-radius: 10px;
	z-index: 3;
	background-color: #f4f4f4;
	margin: 20% auto;
	width: 60%;
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, .2), 0 7px 20px 0 rgba(0, 0, 0, .17);
}

.modal-body {
	white-space: normal;
	line-height: 1.2;
	font-size: 1.1rem;
	padding: 10px 20px;
}

.modal-open {
	cursor: pointer;
	text-align: center;
	border: 1px solid #12b05a;
	border-radius: 5px;
	padding: 10px;
	background: transparent;
	text-decoration: none;
	color: #f1f1f2;
	font-size: 1rem;
}

.modal-footer {
	background: #272669;
	padding: 10px;
	color: #fff;
	text-align: center;
	border-radius: 0 0 10px 10px;
}

.closeBtn {
	font-size: 30px;
	color: #fff;
	float: right;
}

.closeBtn:hover,.closeBtn:focus {
	color: #12b05a;
	text-decoration: none;
	cursor: pointer;
}

/***>>Media Queries***/

@media screen and (max-width: 1625px) {

	#bottom .company_brand {
		margin-left: 4em;
	}
}

@media screen and (max-width: 1450px) {

	#top {

		.phone {
			padding: 13px 80px 12px 100px;
			text-align: left;
		}

		.bill {
			padding: 15px 80px;
		}
	}

	#bottom {
		align-items: center;

		.dropdown_background {
			height: 105px;
			bottom: -100px;
		}

		.nav_items {

			ul .item {
				padding: 25px;

				.dropdown {
					top: 60px;

					li {
						margin-left: 25px;
					}
				}
			}

			.btn {
				width: 80px;
				margin-right: 15px;
			}
		}

		.company_brand {
			margin-left: 1em;
			margin-top: 1em;
			margin-bottom: 1em;
		}
	}

	#promo {
		height: 45px;

		.promo_container {

			&::before, &::after {
				width: 997em;
			}

			&::after {
				margin: 0 0 0 2em;
			}
		}
	}
}

@media screen and (max-width: 1115px) {

	#bottom {

		.company_brand {
			margin-top: 0;
			margin-bottom: 0;
		}

		.nav_items .btn_container {
			display: flex;
			flex-direction: column;

			.btn:not(:last-child) {
				margin-bottom: 5px;
			}
		}
	}
}

@media screen and (max-width: 950px) {

	#top {

		.phone {
			padding: 11px 83px 10px 340px;
		}

		.bill {
			padding: 13px 90px;
		}
	}

	#promo {
		overflow: unset;

		&::before {
			content: none;
		}

		&::after {
			content: none;
		}

		.promo_container {

			&::before, &::after {
				content: none;
				// width: 997em;
			}

			&::after {
				content: none;
				// margin: 0 0 0 2em;
			}
		}
	}

	#bottom {

		.nav_items {
			font-size: 1.5vw;

			ul .item {
				padding: 30px 10px;

				.dropdown {

					li {
						margin-left: 30px;

						a {
							padding: 10px 0;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1115px) {

	#bottom {
		position: relative;

		.dropdown_background {
			position: fixed;
			top: -999px;
		}

		.company_brand {
			padding: 10px;

			img {
				width: 100%;
				height: auto;
				max-width: 210px;
				max-height: 35px;
			}
		}

		.nav_items {
			display: none;
			font-size: 1.2rem;

			.btn_container {
				width: 100%;
				display: flex;
				flex-direction: column;
			}

			&.open {
				display: flex;
				z-index: 101;
				flex-direction: column;
				position: absolute;
				top: 60px;
				background-color: #12974a;
				width: 100%;

				.btn_container {

					.btn {
						width: auto;
						margin: 8px 20px;
					}
				}

				ul {
					width: 100%;
					flex-direction: column;
					margin-top: 20px;

					&:first-child:before {
						content: '';
						position: absolute;
						width: 90%;
						height: 1px;
						background: $light_grey;
					}

					.item {
						display: block;
						margin: auto 30px;
						width: 90%;
						padding: 15px 5px;

						.dropdown {
							display: none;

							&::before {
								content: none;
							}
						}

						&.mobile_only {
							display: block;
							visibility: visible;
						}

						h3::after {
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 1px;
							background: $light_grey;
						}

						h3:not(:last-child)::before {
							visibility: visible;
						}

						&:not(.link):hover {

							&:after {
								visibility: hidden;
							}
						}

						&::after {
							visibility: hidden;
						}

						&::before {
							visibility: visible;
						}

						&:hover {

							.dropdown {
								padding: 0;
							}
						}

						&.clicked {

							h3::before {
								transform: rotate(225deg);
								transition: transform .3s ease-in;
							}

							.dropdown {
								display: contents;
								visibility: visible;

								li:first-child {
									margin-top: 10px;

									&::before {
										content: '';
										position: absolute;
										width: 20px;
										height: 1px;
										background: $light_grey;
									}
								}

								li {
									font-size: 1rem;
									list-style-type: none;
									line-height: 1.8;
									margin-left: 0;
								}
							}
						}
					}
				}

				.btn {
					width: 80%;
					max-width: none;
					margin: 10px 20px;
				}

				.mbl_nav {
					visibility: visible;
					display: block;
					border: none;
				}
			}
		}

		.hamburger {
			visibility: visible;
		}
	}

	#promo {
		height: 40px;
		overflow: unset;

		&::before {
			content: none;
		}

		&::after {
			content: none;
		}

		.promo_item_content {

			p {
				font-size: 1rem;
			}
		}
	}
}

@media screen and (max-width: 575px) {

	#promo {

		.promo_item_content {

			p {
				white-space: normal;
				font-size: .9rem;
			}

			img {
				display: none;
			}

			a {
				color: transparent;
				position: absolute;
				// right: -999px;
				height: 100%;
				width: 102%;
				border: none;
			}
		}
	}

	.modal-content {
		width: 90%;
	}

	.modal-open {
		font-size: .9rem;
	}
}

@media screen and (max-width: 500px) {

	#top {

		.phone {
			padding: 11px 20px 10px 100px;
			text-align: left;
		}

		.bill {
			padding: 13px 20px;
		}
	}
}
