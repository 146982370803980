
.contact-content {
	display: flex;
	width: 100%;

	.contact-left-area {
		padding-left: 20px;
		max-width: 53%;

		.contact-form-area {
			padding: 31px 46px;
			margin: 0 76px 0 111px;
			border: 4px solid #cfd0d2;
			border-radius: 5px;
			line-height: 1.5;

			h1 {
				font-family: $secondary_font;
				font-size: 2.25rem;
				padding-bottom: 23px;
				color: $primary_dark;
			}

			p {
				font-weight: 700;
				padding: 20px 0;

				a {
					color: $primary;
				}
			}

			hr {
				background-color: #cfd0d2;
				border: 1.5px solid #cfd0d2;
			}

			#contact_form {
				display: flex;
				flex-direction: column;
				font-weight: 700;
				padding-top: 20px;
				position: relative;

				.error {
					border: 1px solid #b10101;
				}

				.response_message {

					.error {
						border: none;
						color: red;
					}

					.success {
						padding: 10px;
						border-radius: 5px;
						color: green;
					}
				}

				label {
					padding: 5px;
					font-size: 1.1rem;
				}

				input {
					border: 2px solid #cfd0d2;
					padding: 15px;
					align-items: center;
					border-radius: 5px;
					margin-bottom: 20px;
				}

				.select-wrapper {
					position: relative;

					select {
						border: 2px solid $primary;
						padding: 15px;
						align-items: center;
						border-radius: 5px;
						margin: 20px 0;
						-webkit-appearance: none;
						-moz-appearance: none;
						text-indent: 1px;
						text-overflow: '';
						position: relative;
						cursor: pointer;
						width: 100%;

						&.open:after {
							transform: translateY(-50%) rotate(135deg);
						}
					}

					&:after {
						display: block;
						content: "";
						position: absolute;
						color: #fff;
						top: 50%;
						right: 20px;
						height: 16px;
						width: 16px;
						border-top: 5px solid $primary;
						border-right: 5px solid $primary;
						transform: translateY(-50%) rotate(45deg);
						cursor: pointer;
						transition: transform .3s;
						transform-origin: center 25%;
						pointer-events: none;
					}

					&.open:after {
						transform: translateY(-50%) rotate(135deg);
					}
				}

				textarea {
					border: 2px solid #cfd0d2;
					padding: 15px;
					align-items: center;
					margin-bottom: 20px;
				}

				button {
					width: 40%;
					cursor: pointer;
				}
			}
		}
	}




	.contact-right-area {
		padding: 70px 72px;
		width: 47%;
		background: linear-gradient(177deg, rgba(255, 255, 255, 1) 5%, rgba(199, 196, 196, 1) 100%);

		.right-area-title {
			display: flex;
			font-size: 2.25rem;
			font-family: $secondary_font;
			align-items: center;
			justify-content: center;
			color: $primary_dark;


			img {
				padding: 0px 28px 0px 5px;
			}
		}

		.right-area-loc-wrapper {
			margin: 30px 22px;
			background: linear-gradient(180deg, rgba(245, 244, 244, 1) 15%, rgba(199, 196, 196, 1) 50%, rgba(245, 244, 244, 1) 89%);
			position: relative;
			border: 1px solid #cfd0d2;
		}

		.loc-content {
			background-color: white;
			padding: 0 22px 33px 77px;
			right: 0px;
			top: 0;
			max-width: 532px;
			border: thin solid #cfd0d2;
			line-height: 2;
			margin-left: 41px;

			.loc-number-box {
				padding: 8px 26px;
				border: 2px solid $primary;
				border-radius: 15px;
				font-family: $secondary_font;
				font-size: 1.5rem;
				position: absolute;
				left: 17px;
				top: 80px;
				background-color: white;
				width: 13px;
			}

			.loc-inner-wrapper {
				display: flex;
				flex-direction: column;

				h3 {
					font-size: 1.2rem;
					font-family: $secondary_font;
					color: $primary_dark;
				}

				hr {
					width: 100%;
				}

				a {
					text-decoration: none;
					color: black;
					text-align: center;
					max-width: 280px;
					cursor: pointer;
					margin-left: 10px;
				}

				.loc-address {
					display: flex;
					align-items: center;

					img {
						width: 15px;
						height: 17px;
						background-color: $light_grey;
						border-radius: 15px;
						padding: 6px;
					}

					p {
						padding-left: 10px;
					}
				}

				.loc-phone-numb {
					display: flex;
					align-items: center;

					img {
						height: 17px;
						padding: 5px;
					}

					p {
						padding-left: 10px;
						font-family: $secondary_font;
						font-size: 1.1rem;
					}
				}

				.loc-btn {
					background-color: $primary;
					color: white;
					margin-top: 20px;
					transition: background .3s;

					&:hover {
						background-color: $primary_dark;
					}
				}
			}
		}
	}
}

#hp_wrap {
	position: fixed;
	top: -9999px;
	left: -9999px;
}


/***>>>Media Queries***/

@media screen and (max-width: 1250px) {

	.contact-content {

		.contact-right-area {
			padding: 70px 42px;

			.right-area-loc-wrapper {
				margin: 21px 0;
			}

			.right-area-title {
				font-size: 1.6rem;
			}
		}

		.contact-left-area {

			.contact-form-area {

				h1 {
					font-size: 1.6rem;
				}
			}
		}
	}
}

@media screen and (max-width: 1100px) {

	.contact-content {

		.contact-right-area {
			padding: 69px 25px;

			.loc-content {

				.loc-inner-wrapper {

					a {
						max-width: 255px;
					}
				}

				.loc-number-box {
					padding: 3px 21px;
				}
			}

			.right-area-loc-wrapper {
				margin: 21px 0;
			}

			.right-area-title {
				font-size: 1.4rem;
			}
		}

		.contact-left-area {
			padding-left: 0;

			.contact-form-area {
				padding: 31px 44px;
				margin: 0 45px;

				h1 {
					font-size: 1.4rem;
				}
			}
		}
	}
}

@media screen and (max-width: 950px) {

	.contact-content {

		.contact-right-area {
			padding: 36px 25px;

			.loc-content {
				padding: 0 22px 28px 37px;

				.loc-inner-wrapper {

					a {
						max-width: 255px;
					}
				}

				.loc-number-box {
					padding: 0 17px;
				}
			}

			.right-area-loc-wrapper {
				margin: 21px 0;
			}

			.right-area-title {
				font-size: 1.3rem;

				img {
					padding: 0 25px 0 0;
				}
			}
		}

		.contact-left-area {
			padding-left: 0;

			.contact-form-area {
				padding: 12px 17px;
				margin: 0 25px;

				#contact_form {

					label {
						font-size: 1rem;
					}
				}

				h1 {
					font-size: 1.4rem;
				}
			}
		}

		#footer {
			margin: 0 auto;
		}
	}
}

@media screen and (max-width: 750px) {

	.contact-content {
		flex-direction: column;

		.contact-right-area {
			padding: 36px 25px;
			width: 91%;

			.loc-content {
				padding: 0 22px 28px 37px;

				.loc-inner-wrapper {

					a {
						max-width: 255px;
					}
				}

				.loc-number-box {
					padding: 0 17px;
					top: 65px;
				}
			}

			.right-area-loc-wrapper {
				margin: 21px 0;
			}

			.right-area-title {
				font-size: 1.3rem;

				img {
					padding: 0 25px 0 0;
				}
			}
		}

		.contact-left-area {
			padding-left: 0;
			max-width: 100%;

			.contact-form-area {
				padding: 12px 17px;
				margin: 0 25px;

				p {
					padding: 0;
				}

				#contact_form {

					label {
						font-size: 1rem;
					}

					button {
						width: 48%;
					}

					&:after {
						top: 48%;
					}
				}

				h1 {
					font-size: 1.4rem;
				}
			}
		}

		#footer {
			margin: 0 auto;
		}
	}
}


