.internal_content.tools {
	max-width: 100%;
}

body {

	#sc-button, #size_guide_unit_button {
		/*stylelint-disable at-rule-no-unknown*/
		@extend .btn;
		font-family: $font;
		font-size: 18px;
		font-weight: 700;
		max-width: 265px;
		width: 100%;
		padding: 18px 0;
		box-shadow: 4px 10px 8px rgba(#000, .2);
		border: none;
		/*stylelint-enable at-rule-no-unknown*/
	}

	#sc-button {
		padding: 8px 0;

		&:hover {
			margin: -32px 0 32px;
			box-shadow: 4px 10px 8px rgba(#000, .2);
		}
	}
}