#facility_size_filters {
	background-color: $primary;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $light_grey;
	padding: 20px;

	h3 {
		font-size: 1.5rem;
		font-weight: 600;
	}

	.filters_list {
		display: flex;
		align-items: center;
	}

	.filter {
		font-size: 16px;
		font-weight: 700;
		cursor: pointer;
		margin: 0 0 0 40px;

		&:before {
			content: '';
			background: #fff;
			height: 16px;
			transition: background .3s;
			width: 16px;
			margin: 0 13px 0 0;
			position: relative;
			top: 2px;
			display: inline-block;
			border-radius: 50%;
			border: 1px solid $primary;
		}

		&:hover {

			&:before {
				background: rgba($primary_dark, .5);
			}
		}

		&.active {

			&:before {
				background: $primary_dark;
			}
		}
	}
}

#unit_table {
	width: 100%;
	max-width: 1345px;
	margin: 0 auto 40px;

	.unavailable {
		display: none;
	}

	tr {
		border-bottom: 2px solid $light_grey;
	}

	th, td {
		vertical-align: middle;
		text-align: center;
	}

	th {
		font-size: .9rem;
		font-weight: 300;
		color: $dark_grey;
		padding: 15px 5px 5px 5px;
	}

	td {
		padding: 10px 5px;
	}

	.unit_img {

		span {
			width: 75px;
			height: 75px;
			border-radius: 50%;
			display: flex;
			margin: 0 auto;
			justify-content: center;
			align-items: center;
			background: $light_grey;
		}

		img {
			flex-shrink: 0;
			max-width: 70%;
			max-height: 70%;
		}
	}

	.unit_size {

		.size {
			color: $primary;
			font-weight: 700;
			font-size: 31px;
			margin: 0 auto 10px;
		}

		.type {
			font-weight: 300;
			color: #4d4d4d;
			font-size: 14px;
		}
	}

	.unit_features {

		p {
			display: none;
			text-align: left;
			font-size: 1.2rem;
			padding: 0;
		}

		ul {
			margin-top: 10px;

			li {
				padding: 5px 0 5px 30px;
				white-space: nowrap;
				line-height: 1.3;
				list-style: none;
				background-image: url(/dist/images/icons/ic_lg_check.svg);
				background-repeat: no-repeat;
				background-position: left center;
				background-size: 20px;
				font-size: 14px;
				font-weight: 700;
			}
		}
	}

	.unit_special {
		color: $primary;
		font-weight: 700;
		font-size: 16px;
	}

	.unit_rent {

		.mobile_img {
			display: none;
		}

		.was {
			font-size: 16px;
			font-weight: 300;
			float: left;
			width: 50%;
			color: #666;
			position: relative;
			padding-top: 6px;
			text-decoration: line-through;

			.label {
				font-size: 12px;
				font-weight: 700;
				margin: 0 0 5px;
			}
		}

		.now {
			font-size: 24px;
			font-weight: 700;
			color: #000;
			float: right;
			width: 50%;

			.label {
				font-size: 15px;
				color: $primary;
				margin: 0 0 5px;
				align-self: center;
			}
		}
	}

	.unit_buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 15px 0;

		.btn_container {
			display: flex;
			flex-direction: column;
		}

		.btn {
			font-size: 16px;
			font-weight: 600;
			width: 130px;
			line-height: 31px;
			border-width: 4px;
			margin: 0 0 8px;
			padding: 4px 8px;
			border: 0;
			border-radius: 0;
			box-shadow: 4px 4px 8px rgba(#000, .5);

			&.wait {
				background: #fff;
				color: $primary;

				&:hover {
					background: lighten($primary, 50%);
				}
			}

			&.reserve {
				background-color: #f3f3f5;
				border-color: $dark_grey;
				color: $dark_grey;

				&:hover {
					background-color: lighten($dark_grey, 50%);
					color: $dark_grey;
				}
			}
		}

		.limited {
			font-size: 15px;
			font-weight: 300;
			display: block;
			margin: 0 0 5px;
		}
	}
}

#no_units, #no_units_available {
	text-align: center;
	padding: 35px;
	font-size: 18px;
	font-weight: 700;
	color: $primary;
	line-height: 1.8;
}

#no_units {
	display: none;
}

@media screen and (max-width: 1100px) {

	#unit_table {

		.unit_rent {

			.was, .now {
				float: none;
				width: auto;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#facility_filters {
		margin: 10px;
	}

	#facility_size_filters {
		border-radius: 5px;
		flex-direction: column;
		padding: 0;

		h3 {
			text-align: center;
			width: 100%;
			border-radius: 5px 5px 0 0;
			font-size: 1.2rem;
			font-weight: 400;
			padding: 10px 0;
			font-family: $secondary_font;
			background: linear-gradient(90deg, $primary_dark 0%, $primary 100%);
		}

		.filters_list {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			width: 100%;
			padding: 20px 0;
			line-height: 2.3;
		}
	}

	#unit_table {
		padding: 0 10px;

		tr:first-child {
			display: none;
		}

		tr {
			border: none;
		}

		.mobile_img {
			display: block;
		}

		.unit_rent {
			display: flex;
			padding: 0;

			.mobile_img {
				display: block;

				span {
					width: 75px;
					height: 75px;
					display: flex;
					margin: 0 auto;
					justify-content: center;
					align-items: center;
					background: $light_grey;
				}

				img {
					flex-shrink: 0;
					max-width: 70%;
					max-height: 70%;
				}
			}

			.unit_prices {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				padding: 20px 0;

				.was {
					margin-right: 20px;
				}
			}
		}

		.unit_row {
			display: flex;
			flex-direction: column;
			border: 2px solid $dark_grey;
			border-radius: 5px;
			margin: 10px;

			td:not(:first-child):not(:last-child) {
				width: 90%;
				border-bottom: 2px solid $light_grey;
				margin: auto;
			}

			.unit_img {
				display: none;
			}

			.unit_size {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.size, .type {
					margin: 0;
				}
			}

			.unit_features {

				p {
					display: block;
				}

				li {
					line-height: 1;
				}
			}

			.unit_special {
				padding: 10px 0;
			}
		}

		.unit_prices {

			.was {
				font-size: 1.3rem;
			}

			.now {
				font-size: 2rem;
			}
		}

		.unit_buttons {
			justify-content: space-around;
			background: linear-gradient(90deg, #fff 0%, #ecebea 50%, #fff 100%);
			width: 90%;
			gap: 15px;
			margin: auto;

			.limited {
				font-family: $secondary_font;
				font-size: 1rem;
				color: $dark_grey;
				margin: 0;
			}

			.btn_container {
				flex-direction: row;
			}

			.btn {
				margin: 0 10px;
			}
		}
	}
}

@media screen and (max-width: 500px) {

	#facility_size_filters {

		.filters_list {

			.filter {
				font-size: .7rem;
				margin: 0 0 0 20px;
			}
		}
	}
}