.tips-wrapper {
	font-family: $font;
	font-size: 1.30rem;
	color: $primary_dark;

	.tips-title-wrapper {

		p {
			font-family: $secondary_font;
		}
	}
}

.tips-title-wrapper {
	background-color: #ecebea;
	padding: 43px 273px;

	p {
		font-size: 2.3rem;
	}
}

.tips-content-wrapper {
	padding: 0 273px;
	line-height: 1.4;

	.tips-title {
		font-size: 1.5rem;
		font-family: $secondary_font;
		padding: 20px 42px;
	}

	ul {
		padding: 15px 63px;

		li {
			padding: 15px 20px;
			position: relative;
			font-size: 1.2rem;

			&:after {
				content: '';
				height: .8em;
				width: .8em;
				background: $primary;
				display: block;
				position: absolute;
				top: 1.1em;
				left: -16px;
			}
		}
	}

	p:nth-child(3) {
		font-size: 1.4rem;
		padding: 25px 59px 15px 40px;
	}
}

@media screen and (max-width:1100px) {

	.tips-title-wrapper {
		padding: 43px 96px;
	}

	.tips-content-wrapper {
		padding: 65px 100px;
	}
}

@media screen and (max-width:750px) {

	.tips-title-wrapper {
		padding: 43px 96px;
		text-align: center;
	}

	.tips-content-wrapper {
		padding: 35px 51px;

		p {
			padding: 25px 70px 25px 0;
		}
	}
}

@media screen and (max-width:550px) {

	.tips-title-wrapper {
		padding: 28px 15px;
		text-align: center;

		p {
			font-size: 1.9rem;
		}
	}

	.tips-content-wrapper {
		padding: 25px 25px;

		ul {
			padding: 3px 10px 13px 46px;

			li {
				font-size: 1rem;
				padding: 15px 12px;

				&:after {
					height: .6em;
					width: .6em;
					top: 1.3em;
				}
			}
		}

		.tips-title {
			font-size: 1.2rem;
			padding: 20px 28px;
		}

		p {
			padding: 25px 50px 25px 0;
			font-size: 1rem;
		}

		p:nth-child(3) {
			font-size: 1rem;
			padding: 0 27px 15px 27px;
		}
	}
}
