#facility_top_wrapper {
	margin: 25px 0;
}

#facility_top_container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
}

#facility_info_container {
	text-align: center;
	min-width: 330px;

	h2 {
		pointer-events: none;
		color: $primary_dark;
		font-size: 2.2rem;
		margin: 20px 0;
		width: 50%;
	}

	.facility_data {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
	}
}

#facility_info_data {
	width: 100%;
	max-width: 1072px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: left;
	box-shadow: 0px 10px 10px 1px rgba(0, 0, 0, .27);
}

#facility_info_cta {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-content: center;
	text-align: center;
	padding: 9px 0;
	height: 40px;

	.phone {
		// border-right: 1px solid $light_grey;
		height: 100%;
	}

	.email {
		height: 100%;
	}

	a {
		text-decoration: none;
		color: black;
		margin: 5px;
		// flex-basis: 50%;
	}
}

#facility_info {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	border-top: 1px solid $light_grey;

	.subtitle {
		font-size: 1rem;
		color: $primary_dark;
		padding: 10px 0;

		img {
			margin-right: 10px;
			height: 20px;
			width: 20px;
		}
	}

	div {
		padding: 20px 30px;
		line-height: 1.2;
		font-size: .9rem;
	}
}

#facility_caro_wrapper {
	display: flex;
	flex-grow: 1;
	margin: 0 0 0 85px;
}

#facility_caro_toggles {
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	.toggle {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: $light_grey;
		cursor: pointer;
		height: 70px;
		box-sizing: border-box;
		padding: 10px;
		margin: 10px;
		border-radius: 6px;
		transition: background .3s;

		img {
			width: 30px;
			margin: 0 0 5px;

			&.map {
				width: 22px;
			}
		}

		span {
			color: #939598;
			font-size: 12px;
		}

		&.active {
			background: $primary;

			span {
				color: #fff;
			}
		}
	}
}

#facility_caro_container {
	display: flex;
	flex-grow: 1;
}

#facility_caro {
	width: 100%;
	height: 360px;
	flex-grow: 1;
}

#caro_left_chevron, #caro_right_chevron {
	cursor: pointer;
}

#facility_map {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	opacity: 0;
	pointer-events: none;
	transition: opacity .3s;

	&.active {
		pointer-events: all;
		opacity: 1;
	}

	.map_pin {

		svg {
			fill: $primary;
		}
	}
}

#facility_video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	z-index: 1;
	pointer-events: none;
	transition: opacity .3s;

	&.active {
		pointer-events: all;
		opacity: 1;
	}

	iframe {
		width: 100%;
		height: 360px;
	}
}

@media screen and (max-width: 1120px) {

	#facility_top_container {
		flex-wrap: wrap;
		justify-content: center;
	}

	#facility_info_container {
		margin-bottom: 20px;
		min-width: 0;
	}

	#facility_caro_wrapper {
		margin: 0;
		flex-direction: column;
	}

	#facility_size_filters {
		flex-direction: column;

		h3 {
			margin-bottom: 20px;
		}

		.filters_list .filter {
			margin: 0 0 0 15px;
		}
	}
}

@media screen and (max-width: 750px) {

	#facility_top_container {
		padding: 0;
	}

	#facility_info_cta {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
	}

	#facility_info_container {
		margin-bottom: 0;
		width: 90%;

		h2 {
			pointer-events: all;
			width: 100%;

			&.active {

				&::before {
					transform: rotate(225deg);
				}
			}

			&::before {
				content: '';
				position: absolute;
				display: block;
				visibility: visible;
				width: 12px;
				height: 12px;
				border-top: 4px solid #364f46;
				border-left: 4px solid #364f46;
				right: 10px;
				top: 10px;
				transform: rotate(135deg);
				transition: .4s ease-in-out;
			}
		}

		.subtitle {
			font-size: 1.3rem;
			text-align: left;
			padding: 0;
			position: relative;
		}

		.facility_data {
			flex-direction: column;
		}
	}

	#facility_info_data {
		display: none;
		text-align: left;
		grid-template-columns: auto;
		box-shadow: none;
		margin-top: 3em;

		a {
			margin: 10px 0;
			font-size: .8rem;
			word-wrap: break-word;
		}

		.phone {
			font-family: $secondary_font;
			border: none;
		}
	}

	#facility_info {
		flex-direction: column;
		border: none;
		margin-top: 10px;

		.subtitle {
			font-size: 1rem;
		}

		div {
			padding: 1em 0;
			margin: 0;
			font-size: .8rem;
		}

		div:nth-child(2) {
			margin: 0;
		}
	}

	#facility_caro_wrapper {
		display: none;
		width: 100%;
		flex-direction: column-reverse;
	}

	#facility_caro_toggles {
		flex-direction: row;

		.toggle {
			min-width: 100px;
		}
	}

	#facility_nav_wrapper {
		display: none;
		width: 100%;
	}
}