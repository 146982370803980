#facility {
	max-width: 1920px;
	width: 100%;
	margin: 0 auto;
}

#facility_nav_wrapper {
	background-color: $light_grey;
}

#facility_nav {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	li {
		margin: 15px;

		a {
			background-color: #fff;
			color: black;
			text-align: center;
			border: 1px solid #12b05a;
			width: 134px;
			padding: 15px 32px;
			white-space: nowrap;

			&:hover {
				background-color: $primary;
				color: #fff;
			}
		}
	}
}

#facility_features {
	background-color: $light_grey;
	background-image: url(/dist/images/icons/ic_gray_check.svg);
	background-repeat: no-repeat;
	background-position: calc(100% + 250px) 100%;

	.features_container {
		max-width: 975px;
		margin: auto;
		padding: 5.5em;

		.subtitle {
			margin-bottom: 1.5em;
		}
	}

	ul {
		display: grid;
		align-items: center;
		padding: 0 15px;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

		li {
			padding: 10px 0 10px 40px;
			text-align: left;
			line-height: 1.3;
			list-style: none;
			background-image: url(/dist/images/icons/ic_lg_check.svg);
			background-repeat: no-repeat;
			background-position: left center;
			background-size: 25px;
			margin: 0 0 0 20px;
			font-size: 14px;
		}
	}
}

#facility_about {
	background-color: $primary;
	color: $light_grey;

	h3 {
		display: none;
	}

	.about_container {
		max-width: 975px;
		margin: auto;
		padding: 5.5em;

		.subtitle {
			color: $light_grey;
			margin-bottom: 1.5em;
			padding: 0 15px;
			font-size: 25px;
		}

		p {
			line-height: 1.5;
			padding: 0 15px;
		}
	}
}

#facility_caro_container {
	position: relative;
	min-width: 460px;
	z-index: 0;

	.chevron {
		position: absolute;
		top: 50%;
		right: 0;
		height: 25px;
		width: 25px;
		background-image: url(/dist/images/icons/ic_dg_arrow.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		transform: rotate(-180deg);

		&.left {
			transform: rotate(0deg);
			left: 0;
			position: absolute;
			// right: auto;
			z-index: 1;
		}
	}
}

#packaging_supplies {

	&.facility {
		// background-color: #fff;
		// background-image: none;
		// padding: 0;
		// display: block;

		// div:first-child {
		// 	max-width: none;

		hgroup {

			h2 {
				padding: 1em 0;
			}

			p {
				display: none;
			}
		}

		// 	.features_container {
		// 		display: flex;
		// 		flex-direction: column;

		// 		.feature_item {
		// 			margin: 0 0 0 20px;
		// 		}
		// 	}
		// }

		.supplies_img {
			display: none;
		}
	}
}

#facility_caro {
	width: 100%;
	height: 288px;
	flex-grow: 1;

	/* stylelint-disable */
	.caro_inner {
		overflow: hidden;
		height: 100% !important;
		margin: 0;
	}

	.caro_slide {
		top: 0 !important;
		height: 100%;
		margin: 0 !important;
		background-position: center center;
		background-size: cover;
	}
	/*stylelint-enable*/
}

.mbl_size_guide {
	display: block;

	.subtitle {
		background-color: #ecebea;
		text-align: center;
		padding: 1em 0;
	}
}

#size_guide_unit_button {
	background-color: $primary;
	border-radius: 0;
}
/***>>>Media Queries***/

@media screen and (max-width: 950px) {

	#facility_caro_container {
		min-width: 330px;
	}
}

@media screen and (max-width: 750px) {

	#facility_nav {
		flex-direction: column;
		padding: 20px 0;

		li {
			margin: 5px 0;

			.btn {
				font-size: .9rem;
				padding: 10px 20px;
				color: $primary_dark;
				box-shadow: 0 0 10px #000;
			}
		}
	}

	#facility_caro_container {
		min-width: 0;
	}

	#facility_features {
		background-image: none;

		.features_container {
			padding: 0;

			.subtitle {
				text-align: center;
				margin: 0;
				padding: 1em 0;
			}

			.features_list {
				background-color: #fcfeff;
				padding: 2em 0 4em 0;
			}
		}

		ul li {
			font-size: .9rem;
		}
	}

	#facility_about {

		h3 {
			display: block;

			&.subtitle {
				background-color: #ecebea;
				text-align: center;
				padding: 1em 0;
			}
		}

		.about_container {
			padding: 1em;

			.subtitle {
				font-size: 1.2rem;
			}

			p {
				font-size: .9rem;
			}
		}
	}

	#mbl_size_guide {
		display: block;
	}

	.facility {

		#size_guide_unit_button {
			display: none;
		}
	}

	#packaging_supplies.facility {

		div:first-child {

			.features_container {
				display: grid;
				grid-template-columns: 1fr;
			}
		}
	}
}