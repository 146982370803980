#search_map_locations {
	max-width: 1920px;
	margin: 0 auto;
}

#maps_wrapper {
	display: flex;
	min-height: 350px;
}

.internal_heading {

	h2 {
		margin: 0;
	}
}

.contact-right-area {
	padding: 0 72px;
	width: 47%;
	background: linear-gradient(177deg, rgba(255, 255, 255, 1) 5%, rgba(199, 196, 196, 1) 100%);

	.right-area-title {
		display: flex;
		font-size: 2.25rem;
		font-family: $secondary_font;
		align-items: center;
		justify-content: center;
		color: $primary_dark;


		img {
			padding: 0px 28px 0px 5px;
		}
	}

	.right-area-loc-wrapper {
		margin: 30px 22px;
		background: linear-gradient(180deg, rgba(245, 244, 244, 1) 15%, rgba(199, 196, 196, 1) 50%, rgba(245, 244, 244, 1) 89%);
		position: relative;
		border: 1px solid #cfd0d2;
	}

	.loc-content {
		background-color: white;
		padding: 0 22px 33px 77px;
		right: 0px;
		top: 0;
		max-width: 532px;
		border: thin solid #cfd0d2;
		line-height: 2;
		margin-left: 41px;

		.loc-number-box {
			padding: 8px 26px;
			border: 2px solid $primary;
			border-radius: 15px;
			font-family: $secondary_font;
			font-size: 1.5rem;
			position: absolute;
			left: 17px;
			top: 80px;
			background-color: white;
			width: 13px;
		}

		.loc-inner-wrapper {
			display: flex;
			flex-direction: column;

			h3 {
				font-size: 1.2rem;
				font-family: $secondary_font;
				color: $primary_dark;
			}

			hr {
				width: 100%;
			}

			a {
				text-decoration: none;
				color: black;
				text-align: center;
				max-width: 280px;
				cursor: pointer;
				margin-left: 10px;
			}

			.loc-address {
				display: flex;
				align-items: center;

				img {
					width: 15px;
					height: 17px;
					background-color: $light_grey;
					border-radius: 15px;
					padding: 6px;
				}

				p {
					padding-left: 10px;
				}
			}

			.loc-phone-numb {
				display: flex;
				align-items: center;

				img {
					height: 17px;
					padding: 5px;
				}

				p {
					padding-left: 10px;
					font-family: $secondary_font;
					font-size: 1.1rem;
				}
			}

			.loc-btn {
				background-color: $primary;
				border-radius: 5px;
				color: white;
				margin-top: 20px;
				transition: background .3s;

				&:hover {
					background-color: $primary_dark;
				}
			}
		}
	}
}

#locations_map {
	margin-left: 100px;

	flex-grow: 1;

	.map_pin {
		position: relative;
		display: block;

		transition: transform .3s;
		transform-origin: bottom center;

		&:hover, &.hover {
			z-index: 1;

			transform: scale(1.3);
		}

		span {
			font-size: 22px;
			font-weight: 700;

			position: absolute;
			top: 8px;
			left: 50%;

			display: flex;

			transform: translateX(-50%);

			color: #fff;

			align-items: center;
			justify-content: center;
		}
	}
}

#footer_top {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	background-color: $primary_dark;
	color: $light_grey;

	div {
		display: flex;
		align-items: center;
	}

	div:nth-child(2) {
		background: radial-gradient(circle, #12b05a 0%, #0e392c 100%);
		padding: 40px 200px;
	}

	hgroup {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-weight: 700;
		line-height: 1.3;
		padding-left: 2em;

		span {
			font-size: 1.2rem;
		}

		h3 {
			font-size: 1.5rem;
			font-family: $secondary_font;
		}
	}

	.btn {
		background-color: $light_grey;
		color: $primary_dark;
		border: 1px solid $primary;
		width: 250px;
		text-align: center;
		font-size: 1.4rem;

		&:hover {
			background-color: #dfe0df;
			border: 1px solid $primary_dark;
		}
	}
}

#footer_bottom {
	display: flex;
	justify-content: space-evenly;
	padding: 3em 1em;

	hgroup {
		max-width: 500px;
		margin-right: 10px;

		p {
			font-size: .9rem;
		}
	}

	.subtitle {
		padding: 0;
		font-size: 1.6rem;
		margin-bottom: 1.5em;
	}
}

#bottom_items {
	display: flex;
	flex-direction: column;
	white-space: nowrap;

	div {
		display: flex;
		margin: 10px 0;

		h3 {
			font-family: $secondary_font;
			font-weight: 800;
			font-size: 1.1rem;
			color: $dark_grey;
		}

		hgroup {
			margin-left: 15px;
			line-height: 1.2;
		}
	}
}

#footer_needs {
	display: none;
	flex-direction: column;
	justify-content: center;
	position: relative;
	align-items: center;
	width: 100%;
	margin: 7.5em 0 0 0;
}

#needs_info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url(/dist/images/content/NorthVan-inside.jpg);
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat;
	min-height: 570px;
	width: 100%;

	.subtitle {
		color: $light_grey;
		font-size: 2.5rem;
		text-shadow: 1px 2px black;
	}

	.item_container {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 15px;

		.info_item {
			display: flex;
			padding: 25px 35px;
			border-radius: 10px;
			margin: 10px;
			background-color: $light_grey;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			img {
				margin: 3.5em;
			}

			.btn {
				font-weight: 700;
				font-size: 1.5rem;
				padding: 15px 60px;
				width: 200px;
				white-space: nowrap;
				text-align: center;
			}
		}
	}
}

/***>>>Media Queries***/

@media screen and (max-width: 1200px) {

	#locations_map {
		margin-left: 0px;
	}

	.contact-right-area {
		padding: 10px;
	}
}

@media screen and (max-width: 750px) {

	#footer_needs {
		display: flex;
		margin: 3em 0;

		/* stylelint-disable */
		#needs_info {
			justify-content: space-around;
			background-image: url(/dist/images/content/img_storageneeds_locations_m.jpg);
			text-align: center;

			.subtitle {
				font-size: 1.4rem;
			}

			.item_container {

				.info_item {
					background-color: transparent;
					padding: 10px 30px;

					img {
						display: none;
					}
				}
			}
		}

		#needs_footer {
			display: none;
		}
		/* stylelint-enable */
	}

	#maps_wrapper {
		flex-direction: column-reverse;
	}

	#map_footer {
		display: none;
	}

	.contact-right-area {
		padding: 36px 25px;
		width: 91%;

		.loc-content {
			padding: 0 22px 28px 37px;

			.loc-inner-wrapper {

				a {
					max-width: 255px;
				}
			}

			.loc-number-box {
				padding: 0 17px;
				top: 65px;
			}
		}

		.right-area-loc-wrapper {
			margin: 21px 0;
		}

		.right-area-title {
			font-size: 1.3rem;

			img {
				padding: 0 25px 0 0;
			}
		}
	}

	#locations_map {
		width: 100%;
		min-height: 350px;
		margin: 0;
	}
}