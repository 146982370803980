#supplies {

	.internal_content {
		max-width: 1500px;
	}

	.internal_heading {
		display: flex;
		align-items: center;

		.vert_line {
			border-left: 1px solid $grey;
			height: 65px;
			left: 50%;
			margin: 0 40px 0 40px;
		}

		.search {
			margin: 0 50px;
			max-width: 300px;
			width: 100%;
			position: relative;

			input {
				width: 100%;
				color: black;
				border: 1px solid $grey;
				border-radius: 5px;
				text-align: center;
				padding: 10px 0;
				font-size: 24px;

				&::placeholder {
					color: black;
				}
			}

			img {
				position: absolute;
				right: 0;
				top: calc(50% - 12px);
				height: 25px;
				padding-right: 10px;
				padding-left: 10px;
				border-left: 1px solid black;
			}
		}

		.filters {
			margin: 0 50px;
			max-width: 300px;
			width: 100%;
			position: relative;
			display: inline-block;

			&::before {
				content: '';
				position: absolute;
				right: calc(0px + 3px);
				top: calc(50% - 5px);
				display: block;
				border-right: 3px solid $primary_dark;
				border-bottom: 3px solid $primary_dark;
				width: 12px;
				height: 12px;
				transform: translate(-50%, -50%) rotate(45deg);
			}

			input {
				border: 1px solid $grey;
				border-radius: 5px;
				text-align: center;
				padding: 10px 0;
				font-size: 24px;
				width: 100%;

				&:focus + .options {
					display: block;
				}
			}

			.options {
				position: absolute;
				top: 100%;
				left: 0;
				background: linear-gradient(0deg, rgba(32, 177, 88, 1) 0%, rgba(15, 121, 73, 1) 100%);
				width: 100%;
				border: 1px solid #ccc;
				border-radius: 5px;
				display: none;
				box-sizing: border-box;
				padding: 15px 0;

				.option {
					padding: 10px;
					margin: 0 10px;
					cursor: pointer;
					color: #fff;
					font-family: $font;
					font-weight: 400;
					font-size: 18px;
					position: relative;

					&::before {
						content: '';
						position: absolute;
						right: calc(0px + 5px);
						top: 50%;
						display: block;
						border-right: 2px solid $primary_dark;
						border-bottom: 2px solid $primary_dark;
						width: 8px;
						height: 8px;
						transform: translate(-50%, -50%) rotate(-45deg);
					}

					&:not(:last-child) {
						border-bottom: 1px solid #fff;
					}

					&:hover {
						color: black;
					}
				}
			}
		}

		h2 {
			width: auto;
		}
	}

	.supplies {
		box-sizing: border-box;
		padding: 30px 10px;
	}

	.supplies_wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}

	.supplies_item {
		display: flex;
		width: 330px;
		min-height: 410px;
		flex-direction: column;
		margin: 10px 10px 20px 10px;
		border-radius: 15px;
		border: 2px solid $light_grey;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);

		img {
			width: 100%;
			height: 100%;
		}
	}

	.supplies_image {
		padding: 20px 15px;
	}

	.supplies_info {
		padding: 15px 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;

		h3 {
			font-weight: 700;
			font-size: 18px;
			margin-bottom: 20px;
		}

		p {
			line-height: 1.2;
			margin-bottom: 20px;
		}
	}

	.supplies_price {
		background-color: $primary;
		border-radius: 0px 0px 15px 15px;
		margin-top: auto;
		padding: 25px 0;

		p {
			text-align: center;
			font-size: 22px;
			color: #fff;
			font-weight: 700;
		}
	}
}

@media screen and (max-width: 1460px) {

	#supplies {

		.supplies_wrap {
			justify-content: center;
			align-items: center;
		}
	}
}

@media screen and (max-width: 1200px) {

	#supplies {

		.internal_heading .vert_line {
			display: none;
		}
	}
}

@media screen and (max-width: 950px) {

	#supplies {

		.internal_heading {

			.filters {
				margin: 0 25px;
			}

			.search {
				margin: 0;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#supplies {

		.internal_heading {

			.filters input {
				font-size: 18px;
			}

			.search input {
				font-size: 18px;
			}
		}
	}
}

@media screen and (max-width: 700px) {

	#supplies {

		.internal_heading {
			flex-direction: column;

			.search {
				max-width: 90%;
				margin: 0 10px 20px 10px;

				input {
					font-size: 16px;
				}
			}

			.filters {
				max-width: 90%;
				margin: 0 10px 20px 10px;

				select, option {
					font-size: 16px;
				}
			}
		}
	}
}
