#footer {
	margin: 35px auto 0 auto;
	width: 100%;
	max-width: 1920px;
	background-color: $primary;
	font-size: .9rem;
	line-height: 1;
	font-family: $font;

	h3 {
		color: $light_grey;
		font-weight: 800;

		&::after {
			content: '';
			display: block;
			width: 25px;
			height: 1px;
			background-color: $light_grey;
			margin: 10px 0;
		}

		a {
			line-height: 1;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	a {
		text-decoration: none;
		color: $light_grey;
		line-height: 2;
	}

	.container {
		display: flex;
		justify-content: space-around;
		padding: 5%;

		.footer_logo {

			img {
				width: 100%;
				height: auto;
			}
		}

		.mobile {
			display: none;

			.mobile_socials {
				display: flex;
				justify-content: center;
				margin-top: 20px;
			}
		}

		.row {
			padding: 0 10px;
		}

		ul li {

			a {
				display: flex;
				align-items: center;

				&:hover {
					text-decoration: underline;
					text-underline-offset: 2px;
				}

				img {
					margin-right: 10px;
				}
			}
		}
	}
}

@media screen and (max-width: 1100px) {

	#footer {

		.container {
			justify-content: space-evenly;
			flex-wrap: wrap;

			.row {
				padding: 10px;
			}
		}
	}
}

/***>>Media Queries***/
@media screen and (max-width: 750px) {

	#footer {

		.container {
			justify-content: center;
			padding: 15px;
		}

		.container img, .row {
			display: none;
		}

		.container .mobile {
			display: inline-block;

			img {
				display: block;
				max-height: 45px;
				margin: 35px 0;
			}

			.mobile_socials img {
				height: 30px;
				margin: 0 5px;
				display: block;
			}
		}
	}
}