.faq-wrapper {
	font-family: $secondary_font;
	font-size: 1.30rem;
	color: $primary_dark;
}

.faq-content-wrapper {
	padding: 0 275px;

	.faqTips-content {
		position: relative;
		cursor: pointer;

		h3 {
			line-height: 1.2rem;
		}

		&.open h3:after {
			transform: translateY(-50%) rotate(135deg);
		}
	}

	hr {
		border: $light_grey thin solid;
	}

	h3 {
		position: relative;
		padding: 25px 165px 25px 0;

		&:after {
			display: block;
			content: "";
			position: absolute;
			color: $primary;
			right: 95px;
			top: 37px;
			height: 19px;
			width: 19px;
			border-top: 3px solid $primary;
			border-right: 3px solid $primary;
			transform: translateY(-50%) rotate(45deg);
			cursor: pointer;
			transition: transform .3s;
			transform-origin: center 25%;
		}
	}


	.faq-text-dropdown {
		display: none;
		line-height: 1.2rem;

		p {
			font-family: $font;
			font-size: 1rem;
			color: black;
			padding-top: 8px;

			a {
				color: #12b05a;
				transition: color .3s;

				&:hover {
					color: #0e392c;
				}
			}

			&:after {
				display: none;
			}
		}
	}
}

@media screen and (max-width:1100px) {

	.faq-title-wrapper {
		padding: 43px 96px;
	}

	.faq-content-wrapper {
		padding: 0 88px;
	}
}

@media screen and (max-width:750px) {

	.faq-title-wrapper {
		padding: 43px 96px;
		text-align: center;
	}

	.faq-content-wrapper {
		padding: 0 51px;

		h3 {
			padding: 25px 70px 25px 0;

			&:after {
				right: 25px;
			}
		}
	}
}

@media screen and (max-width:550px) {

	.faq-title-wrapper {
		padding: 28px 15px;
		text-align: center;

		p {
			font-size: 1.9rem;
		}
	}

	.faq-content-wrapper {
		padding: 0 15px;

		h3 {
			padding: 13px 50px 13px 0;

			&:after {
				right: 12px;
				height: 14px;
				width: 14px;
				top: 31px;
			}
		}

		.faqTips-content {

			h3 {
				line-height: 1.6rem;
				font-size: 1rem;
			}
		}
	}
}