.internal_heading {
	background: $light_grey;
	color: $dark_grey;
	font-family: $secondary_font;
	font-size: 36px;
	font-weight: 800;
	max-width: 1920px;
	margin: 0 auto;

	h2 {
		padding: 70px 50px;
		box-sizing: border-box;
		max-width: 1350px;
		width: 100%;
		margin: 0 auto 80px;
		color: $primary_dark;
	}
}

.internal_content {
	max-width: 1350px;
	width: 100%;
	margin: 0 auto;

	&.error {
		text-align: center;
		line-height: 1.8;

		p {
			margin-bottom: 20px;
			padding: 0 35px;
			color: $dark_grey;
		}
	}

	&.legality {
		padding: 0 35px;
		box-sizing: border-box;

		h3 {
			font-family: $secondary_font;
			font-size: 18px;
			font-weight: 700;
			margin: 0 0 20px;
			line-height: 1.2;
			color: $primary_dark;
		}

		p {
			margin: 0 0 20px;
			line-height: 1.4;
			color: $dark_grey;
		}

		ul, ol {
			line-height: 1.4;
			color: $dark_grey;
			margin: 0 0 1em 3em;
		}

		ul {
			list-style-type: disc;
		}

		ol {
			list-style-type: decimal;
		}

		a {
			color: $primary;
			transition: color .3s;

			&:hover {
				color: $primary_dark;
			}
		}
	}

	&.storage_solutions_content {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: stretch;
		margin: 0 auto 20px;
		padding: 20px 35px 0;
		box-sizing: border-box;

		h3 {
			font-family: $secondary_font;
			font-size: 18px;
			font-weight: 700;
			margin: 0 0 20px;
			line-height: 1.2;
			color: $primary_dark;
		}

		p {
			margin: 0 0 20px;
			line-height: 1.4;
			color: $dark_grey;
		}

		a {
			color: $primary;
			transition: color .3s;

			&:hover {
				color: $primary_dark;
			}
		}

		strong {
			font-weight: 800;
		}

		.storage_solutions_text {
			flex: 1;
			padding: 20px;

			&.vehicle {
				background: $light_grey;
				background-image: url(/dist/images/icons/ic_gray_check.svg);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				padding: 20px 75px 20px 20px;
			}

			&.business_and_commercial {
				padding: 20px 20px 0;
			}

			&.bottom {
				padding: 0 20px 0;
				flex-basis: 100%;
			}
		}

		.storage_solutions_img {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			padding: 20px;

			&.vehicle {
				justify-content: center;
				margin-left: -40px;
				padding: 0;

				.storage_solutions_img_wrapper {
					position: relative;

					&::before, &::after {
						content: "";
						position: absolute;
						width: 50px;
						height: 50px;
						background: $primary;
						z-index: 1;
					}

					&::before {
						bottom: 5px;
						left: -15px;
					}

					&::after {
						top: -15px;
						right: -15px;
					}
				}

				img {
					position: relative;
					margin-bottom: 20px;
					z-index: 2;
				}
			}

			&.business_and_commercial {
				padding: 20px 20px 0;

				img {
					max-width: 500px;

					&.storage_solutions_icon {
						max-width: 250px;
					}
				}
			}

			img {
				max-width: 600px;
				margin-bottom: 20px;

				&.vehicle {
					margin-bottom: 0;
				}

				&.storage_solutions_icon {
					max-width: 300px;
					margin: auto 0;
				}
			}
		}

		.storage_solutions_list {


			.storage_solutions_item {
				display: flex;

				img {
					margin-right: 10px;
				}
			}
		}
	}
}

/* stylelint-disable */

#reserve_confirm {

    #internal_content {
        width: 100%;
        max-width: 1090px;
        margin: 0 auto;

        .page_title {
            padding: 0 24px;

            h1 {
                color: $primary;
            }
        }
    }
    
    #page_content {
        padding: 24px;
    }

    #thank_you_msg {
        line-height: 1.4;
        margin-bottom: 30px;
    }

    #reserve_confirm_inner {
        display: flex;
    }

    #reserve_confirm_sidebar {
        width: 300px;
    }

    #reserve_confirm_content {
        margin-left: 40px;
        flex: 1;
        padding: 40px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    #confirm_details {
        background: #f2f2f2;
        margin-bottom: 15px;
    }

    #confirm_details_img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 80px 10px;
        background-image: url("/dist/images/storage_image.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;

        &::before {
            height: 100%;
            width: 100%;
            background: rgba($primary, 0.75);
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            // z-index: -1;
        }

        h4 {
            padding: 8px 10px;
            background: #fff;
            color: $primary;
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 15px;
            position: relative;
        }

        h3 {
            font-size: 40px;
            font-weight: 700;
            color: #fff;
            position: relative;
        }
    }

    #confirm_details_content {
        padding: 15px;

        h6 {
            font-size: 18px;
            font-weight: 700;
            border-bottom: 1px solid #ccc;
            padding-bottom: 5px;
            margin-bottom: 15px;
            color: #333;
        }

        .confirm_info_line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #333;

            &+.confirm_info_line {
                margin-top: 5px;
            }

            &:last-of-type {
                margin-top: 15px;
            }
        }
    }

    .confirm_section {
        h5 {
            font-size: 20px;
            font-weight: 700;
            border-bottom: 1px solid #ccc;
            padding-bottom: 5px;
            margin-bottom: 10px;
        }

        &+.confirm_section {
            margin-top: 25px;
        }
    }

    .confirm_section_item {
        h6 {
            color: $primary;
            margin-bottom: 3px;
        }

        p {
            line-height: 1.2;
        }

        &+.confirm_section_item {
            margin-top: 15px;
        }
    }

    #reserve_confirm_content {
        h3 {
            font-size: 22px;
            font-weight: 700;
            color: $primary;
            margin-bottom: 10px;
        }
    }

    .reserve_detail_item {
        &+.reserve_detail_item {
            margin-top: 15px;
        }

        h4 {
            font-weight: 700;
            margin-bottom: 3px;
        }
        p {
            line-height: 1.2;
        }

        &+h3 {
            margin-top: 50px;
        }
    }
}

/***>>Media Queries***/

@media screen and (max-width: 1200px) {

	.internal_content {

		&.storage_solutions_content {

			.storage_solutions_img {

				img {
					max-width: 500px;
				}
			}
		}
	}
}

@media screen and (max-width: 1100px) {

	#reserve_confirm {
        #reserve_confirm_inner {
            flex-direction: column;
        }

        #reserve_confirm_sidebar {
            width: auto;
        }

        #reserve_confirm_content {
            margin: 30px 0 0 0;
            padding: 24px;
        }
    }
/* stylelint-enable */

	.internal_content {

		&.storage_solutions_content {
			max-width: 750px;
			flex-direction: column-reverse;

			.storage_solutions_img {
				padding: 0;

				&.vehicle {
					margin-left: 0;

					img {
						margin-bottom: 0;
					}

					.storage_solutions_img_wrapper {

						&::before, &::after {
							content: none;
						}
					}
				}

				&.business_and_commercial {
					order: 3;
					padding: 0;

					img {
						max-width: 100%;
						margin-bottom: 0;
					}
				}

				img {
					max-width: 100%;
					margin-bottom: 0;

					&.storage_solutions_icon {
						display: none;
					}
				}
			}

			.storage_solutions_text {
				padding: 10px 0 0;

				&.vehicle {
					padding: 10px;
				}

				&.business_and_commercial {
					order: 2;
					padding: 10px 0 0;
				}

				&.bottom {
					order: 1;
					padding: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 750px) {

	.internal_heading {
		font-size: 24px;

		h2 {
			padding: 50px;
		}
	}
}

@media screen and (max-width: 500px) {

	.internal_heading {
		font-size: 20px;

		h2 {
			padding: 16px;
			margin: 0 auto 18px;
		}
	}
}
