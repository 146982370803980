/*********************
* Fonts
*********************/
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v27/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v27/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v27/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v27/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

//Orbitron
@font-face {
	font-family: 'Orbitron';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/orbitron/v19/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1ny_CmBoWgz.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$font: "Open Sans", sans-serif;
$secondary_font: "Orbitron", sans-serif;

/*********************
* Colors
*********************/
$primary: #12b05a;
$primary_dark: #0e392c;
$black: #242424;
$dark_grey: #606161;
$grey: #929497;
$light_grey: #f1f1f2;

/*********************
* Reusable Styles
*********************/
/*** ID ***/

#skip_nav {
	position: fixed;
	top: -9999px;
	left: -9999px;
}

.page_container {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-height: 100vh;
	margin: 0 auto;
	font-family: $font;
}

/*** Classes ***/

.btn {
	display: inline-block;
	background: $primary;
	font-weight: 800;
	font-size: 16px;
	padding: 12px 32px;
	border-radius: 5px;
	border: 1px solid $light_grey;
	color: #fff;
	text-decoration: none;
	transition: background .3s;

	&:hover {
		background: $primary_dark;
	}
}

.subtitle {
	font-family: $secondary_font;
	font-size: 30px;
	font-weight: 800;
	color: $dark_grey;
	padding: 0 50px;
	line-height: 1.4;

	@media screen and (max-width: 750px) {
		font-size: 28px;
	}

	@media screen and (max-width: 500px) {
		font-size: 20px;
	}
}