main {
	max-width: 1920px;
	// box-sizing: border-box;
	margin: 0 auto;
	width: 100%;
	position: relative;
}

#to_top {
	position: sticky;
	cursor: pointer;
	display: flex;
	line-height: 2;
	float: right;
	align-items: flex-end;
	margin-right: 10px;
	flex-direction: column;
	bottom: 100px;
	right: 25px;
	width: 78px;
	height: 78px;

	&:hover {

		img {
			filter: drop-shadow(0 0 10px $primary_dark);
		}
	}
}

#home_hero_caro {
	position: relative;
	text-align: center;
	height: 695px;

	hgroup {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 600px;
		z-index: 1;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		h2 {
			font-size: 2.8rem;
			font-weight: 700;
			color: #fff;
			text-shadow: 0 10px 15px #000;
			margin-bottom: 70px;
		}

		.btn {
			padding: 15px 80px;
			font-size: 1.4rem;
			box-shadow: 0 0 30px #000;
		}
	}

	.caro_inner {
		margin: 0;
		height: 100%;
	}

	.caro_slide {
		display: flex;
		justify-content: center;
		align-items: center;
		background-size: cover;
		height: 100%;

		&.img1 {
			background-image: url(/dist/images/bg/img_hero_2.jpg);
		}

		&.img2 {
			background-image: url(/dist/images/bg/img_hero.jpg);
		}

		&.img3 {
			background-image: url(/dist/images/bg/img_hero_3.jpg);
		}
	}

	.dots_container {
		padding: 20px 0;
	}

	.caro_dot {
		background: $light_grey;
		box-shadow: none;
		border: none;
		height: 16px;
		width: 16px;

		&.active {
			background: $primary;
		}
	}
}

#features {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-left: 460px;
	margin-top: 3.4em;
}

#features_info {
	display: flex;
	justify-content: center;
	align-items: center;

	div:first-child {
		max-width: 600px;
	}

	.subtitle {
		padding: 0;
	}

	p {
		margin-top: 40px;
		line-height: 1.1;
		font-size: 16px;
	}

	hr {
		margin: 47px 0;
	}

	.feature_icons {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		margin-top: 30px;

		div {
			padding: 20px 50px 40px 50px;
			display: flex;
			flex-direction: column;
			justify-content: end;
			align-items: center;
			text-align: center;

			h3 {
				font-family: $secondary_font;
				font-weight: 800;
				color: $dark_grey;
				padding-top: 10px;
			}
		}
	}

	.lrg_check {
		background-image: url(/dist/images/icons/grey_checkmark_ic.svg);
		background-repeat: no-repeat;
		background-position: left;
		width: 100%;
		height: 600px;
	}
}

#features_footer {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 3.4em;
	white-space: nowrap;

	div {
		display: flex;
		align-items: center;

		h3 {
			font-family: $secondary_font;
			font-weight: 800;
			font-size: 1.1rem;
			color: $dark_grey;
		}

		&:not(:first-child) {
			margin-left: 6em;
		}

		hgroup {
			margin-left: 15px;
			line-height: 1.2;
		}
	}
}

#easy_steps {
	display: none;
}

#needs {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	align-items: center;
	width: 100%;
	margin: 7.5em 0 0 0;
}

#needs_info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url(/dist/images/bg/img_home_storageneeds.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	min-height: 570px;
	width: 100%;

	&.storage_solutions_content {
		background-position: center;
	}

	.subtitle {
		color: $light_grey;
		font-size: 2.5rem;
	}

	.item_container {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 15px;

		.info_item {
			display: flex;
			padding: 25px 35px;
			border-radius: 10px;
			margin: 10px;
			background-color: $light_grey;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			img {
				margin: 3.5em;
			}

			.btn {
				font-weight: 700;
				font-size: 1.4rem;
				padding: 15px 60px;
				width: 200px;
				white-space: nowrap;
				text-align: center;
			}
		}
	}
}

#needs_footer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	background-color: $primary_dark;
	color: $light_grey;

	div {
		display: flex;
		align-items: center;
	}

	div:nth-child(2) {
		background: radial-gradient(circle, #12b05a 0%, #0e392c 100%);
		padding: 40px 200px;
	}

	hgroup {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-weight: 700;
		line-height: 1.3;
		padding-left: 2em;

		span {
			font-size: 1.2rem;
		}

		h3 {
			font-size: 1.5rem;
			font-family: $secondary_font;
		}
	}

	.btn {
		background-color: $light_grey;
		color: $primary_dark;
		border: 1px solid $primary;
		width: 250px;
		text-align: center;
		font-size: 1.4rem;

		&:hover {
			background-color: #dfe0df;
			border: 1px solid $primary_dark;
		}
	}
}

#packaging_supplies {

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: end;
	padding: 3em 0;
	background-color: #f3f3f5;
	margin-top: 1em;

	div:first-child {
		max-width: 675px;

		hgroup {
			margin: 2em 0 4em 0;

			h2 {
				margin-bottom: 1em;
				padding: 0;
			}

			p {
				line-height: 1.1;
			}
		}

		.features_container {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
			max-width: 600px;
			grid-gap: 20px;
			padding-bottom: 2em;

			.feature_item {
				display: flex;
				font-size: 1.1rem;

				img {
					margin-right: 10px;
				}
			}
		}
	}

	.supplies_img {
		background-image: url(/dist/images/icons/ic_boxes.svg);
		background-repeat: no-repeat;
		background-size: contain;
		height: 400px;
		width: 675px;
	}
}

#size_guide_unit_button {
	/* stylelint-disable */
	background-color: $primary !important;
	border-radius: 0 !important;
	/* stylelint-enable */
}

.hr_footer {
	visibility: hidden;
}

/***>>Media Queries***/
@media screen and (max-width: 1440px) {

	#home_hero_caro {
		height: 495px;
	}

	#features_info {

		.lrg_check {
			width: 50%;
			background-size: contain;
		}
	}

	#features {
		margin: 30px 20px;
	}
}

@media screen and (max-width: 950px) {

	#to_top {
		bottom: 115px;
		width: 60px;
		height: 60px;
	}

	#features_info .lrg_check {
		display: none;
	}

	#features_footer {
		align-items: center;
		flex-direction: column;
		margin-top: 30px;

		div {
			margin: 10px 0;

			&:not(:first-child) {
				margin-left: 0;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	.dkt2mbl {
		display: flex;
		flex-direction: column-reverse;
	}

	.hr_footer {
		visibility: visible;
		width: 90%;
		margin: 2em;

		&.features_hide {
			display: none;
		}
	}

	#home_hero_caro {
		height: 390px;

		hgroup {

			h2 {
				font-size: 1.7rem;
			}

			.btn {
				padding: 10px 60px;
				white-space: nowrap;
			}
		}

		.caro_slide {

			&.img1 {
				background-image: url(/dist/images/content/img_home_m1.jpg);
			}

			&.img2 {
				background-image: url(/dist/images/content/img_faq_m.jpg);
			}

			&.img3 {
				background-image: url(/dist/images/content/img_home_m2.jpg);
			}
		}

		.dots_container {
			display: none;
		}
	}

	#features_info {
		text-align: center;

		hgroup:first-of-type {

			p {
				display: none;
			}
		}

		.features_hide {
			display: none;
		}

		.feature_icons {

			div {
				flex-direction: row;
				justify-content: center;
				padding: 15px 20px;

				h3 {
					margin: 0 0 0 20px;
				}
			}
		}
	}

	#features_footer {

		&.features_hide {
			display: none;
		}
	}

	#easy_steps {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: $light_grey;
		padding: 2em;
		margin-top: 30px;

		h3 {
			font-size: 1.3rem;
			color: $dark_grey;
			text-align: center;
		}

		p {
			padding: 1em 0;
		}

		ul {
			display: flex;
			align-items: baseline;
			padding: 2em 0;

			li {
				position: relative;
				width: 100px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				p {
					font-size: .9rem;
					text-align: center;
					font-family: $secondary_font;
				}

				img {
					width: 30px;
					height: 30px;
				}

				&:not(:last-child) {
					margin-right: 1em;

					&::after {
						display: block;
						content: "";
						position: absolute;
						color: $primary;
						left: 95px;
						top: 10px;
						height: 10px;
						width: 10px;
						border-top: 3px solid $primary;
						border-right: 3px solid $primary;
						transform: translateY(-50%) rotate(45deg);
					}
				}
			}
		}

		a {
			width: 200px;
			text-align: center;
		}
	}

	#needs {
		margin: 0;

		/* stylelint-disable */
		#needs_info {
			justify-content: space-around;
			background-image: url(/dist/images/content/img_home_m2.jpg);
			text-align: center;

			.subtitle {
				font-size: 1.4rem;
			}

			.item_container {

				.info_item {
					background-color: transparent;
					padding: 10px 0;
					margin: 0;

					img {
						display: none;
					}
				}
			}
		}

		#needs_footer {
			display: none;
		}
		/* stylelint-enable */
	}

	#packaging_supplies {
		padding: 2em 1.2em;
		margin: 0;
		background-image: url(/dist/images/icons/grey_checkmark_ic.svg);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;

		hgroup {
			margin: 2em 0 3em 0;
		}

		div:first-child .features_container {
			display: none;
		}
	}
}

@media screen and (max-width: 500px) {

	#features_info {

		.feature_icons {

			div {
				flex-direction: column;

				h3 {
					margin: 0;
				}
			}
		}
	}
}